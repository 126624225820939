import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from '../../services/storage.service';
import { StorageNames } from '../../constants/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from '../../constants/api.endpoint';
import { CookieService } from 'ngx-cookie-service';
import { UserInfoModel } from '../models/user-info.model';
import { JsonPipe } from '@angular/common';

interface PlatformState {
  // Notification settings
  notificationPermission: 'granted' | 'denied' | 'default';
  notificationInteracted: boolean;
  deviceToken: string | null;
  
  // User authentication
  isAuthenticated: boolean;
  userId: string | null;
  userInfo: UserInfoModel | null;
  
  // Verification status
  isVerifyCompleted: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PlatformStateService { 

  // The central state store
  private state = new BehaviorSubject<PlatformState>({
    notificationPermission: 'default',
    notificationInteracted: false,
    deviceToken: null,
    isAuthenticated: false,
    userId: null,
    userInfo: null,
    isVerifyCompleted: false // Default to false
  });

  public isIos: boolean;
  public isLoggedIn: boolean; 
  logged_user: string = ""; 

  // Public observables
  readonly state$ = this.state.asObservable();
  readonly notificationPermission$ = this.state$.pipe(map(state => state.notificationPermission));
  readonly notificationInteracted$ = this.state$.pipe(map(state => state.notificationInteracted));
  readonly deviceToken$ = this.state$.pipe(map(state => state.deviceToken));
  readonly isAuthenticated$ = this.state$.pipe(map(state => state.isAuthenticated));
  readonly userId$ = this.state$.pipe(map(state => state.userId));
  readonly userInfo$ = this.state$.pipe(map(state => state.userInfo));
  readonly isVerifyCompleted$ = this.state$.pipe(map(state => state.isVerifyCompleted));
  
  
  constructor(
    private storageService: StorageService,
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {
    this.initState();
    this.isIos = this.isIosSafari(); 
    this.platformEnableDevMode = true;
  }

  platformEnableDevMode: boolean = true; 
    
    checkLoggedIn(): boolean {
        let userid = this.cookieService.get('user_id');
        if (userid === undefined || userid === '') {
            return false;
        }

        this.logged_user = userid;
        return true;
    }

    getLoggedInUser(): string {
        let userid = this.cookieService.get('user_id');
        if (userid === undefined || userid === '') {
            return "";
        } 

        return this.logged_user = userid;;
    }

    isIosSafari(): boolean {
        let ua = window.navigator.userAgent;
        let iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
        let webkit = ua.match(/WebKit/i);
        let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    
        if (iOSSafari == null) {
            return false;
        } else {
            return true;
        } 
    }
  /**
   * Initialize state from storage
   */
  private initState(): void {
    const currentState = this.state.getValue();
    
    // Load notification settings
    const interacted = this.storageService.getStorage(StorageNames.isNotificationInteracted) || '';
    const permission = this.storageService.getStorage(StorageNames.isNotificationGratedByUser) || '';
    const deviceToken = this.storageService.getStorage(StorageNames.DeviceToken) || null;
    
    // Load user auth info
    const userId = this.cookieService.get('user_id') || null;
    const userInfo: UserInfoModel = JSON.parse(
      this.storageService.getStorage(StorageNames.UserInfo) || 'null'
    );
    
    // Load verification status
    const isVerifyCompleted = this.storageService.getStorage(StorageNames.IsVerifyCompleted);
    
    // Update state
    this.state.next({
      ...currentState,
      notificationInteracted: interacted === 'true',
      notificationPermission: permission === 'true' ? 'granted' : 
                             (permission === 'false' ? 'denied' : 'default'),
      deviceToken,
      isAuthenticated: userInfo?.isLoggedIn || false,
      userId,
      userInfo,
      isVerifyCompleted: isVerifyCompleted === 'true' // Convert string to boolean
    });
  }

  /**
   * Request notification permission
   */
  requestNotificationPermission(): Observable<boolean> {
    // Update interaction state
    this.updateState({
      notificationInteracted: true
    });
    this.storageService.saveStorage(StorageNames.isNotificationInteracted, 'true');
    
    return new Observable<boolean>(observer => {
      Notification.requestPermission()
        .then(permission => {
          const granted = permission === 'granted';
          
          // Update permission state
          this.updateState({
            notificationPermission: permission as 'granted' | 'denied' | 'default'
          });
          this.storageService.saveStorage(
            StorageNames.isNotificationGratedByUser, 
            granted ? 'true' : 'false'
          );
          
          observer.next(granted);
          observer.complete();
        })
        .catch(error => {
          console.error('Permission request error:', error);
          observer.error(error);
        });
    });
  }

  /**
   * Reject notification permission
   */
  rejectNotificationPermission(): void {
    this.updateState({
      notificationInteracted: true,
      notificationPermission: 'denied'
    });
    
    this.storageService.saveStorage(StorageNames.isNotificationInteracted, 'true');
    this.storageService.saveStorage(StorageNames.isNotificationGratedByUser, 'false');
  }

  /**
   * Save device token
   */
  setDeviceToken(token: string): void {
    this.updateState({ deviceToken: token });
    this.storageService.saveStorage(StorageNames.DeviceToken, token);
    
    // Register token with backend if user is authenticated
    const userId = this.state.getValue().userId;
    if (userId) {
      this.registerDeviceToken(token, userId).subscribe();
    }
  }

  /**
   * Register device token with backend
   */
  registerDeviceToken(deviceToken: string, userId: string): Observable<any> {
    if (!deviceToken || !userId) {
      return throwError(() => new Error('Missing device token or user ID'));
    }
    
    const headersData = new HttpHeaders({
      'Content-Type': 'application/json',
      deviceId: deviceToken
    });
    
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.UpdateDevieToken}/${userId}`,
      {},
      { headers: headersData }
    ).pipe(
      catchError(error => {
        console.error('Error registering device token:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Unsubscribe device token
   */
  unsubscribeDeviceToken(): Observable<any> {
    const { deviceToken, userId } = this.state.getValue();
    
    if (!deviceToken || !userId) {
      return of(null); // Nothing to unsubscribe
    }
    
    const headersData = new HttpHeaders({
      'Content-Type': 'application/json',
      deviceId: deviceToken
    });
    
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.UnsubscribeDeviceToken}/${userId}`,
      {},
      { headers: headersData }
    ).pipe(
      catchError(error => {
        console.error('Error unsubscribing device token:', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Set authentication state
   */
  setAuthenticationState(isAuthenticated: boolean, userId?: string, userInfo?: UserInfoModel): void {
    const update: Partial<PlatformState> = { isAuthenticated };
    

    // Check if userId or userInfo is missing when trying to authenticate
    if (isAuthenticated && (!userId || !userInfo)) {
      // Synchronize state first to try to recover missing data
      this.synchronizeUserState();
      
      // If still missing userId, use the one from current state
      if (!userId) {
        userId = this.state.getValue().userId || undefined;
      }
      
      // If still missing userInfo, use the one from current state
      if (!userInfo) {
        userInfo = this.state.getValue().userInfo || undefined;
      }
    }    
    
    if (userId) {
      update.userId = userId;
      this.cookieService.set('user_id', userId, 14, '/');
    }
    
    if (userInfo) {
      update.userInfo = userInfo;
      this.storageService.saveStorage(StorageNames.UserInfo, JSON.stringify(userInfo));
    }

    this.updateState(update);
    
    // Register device token if we have one
    const deviceToken = this.state.getValue().deviceToken;
    if (deviceToken && isAuthenticated && userId) {
      this.registerDeviceToken(deviceToken, userId).subscribe();
    }
  }

  /**
   * Get current user ID
   */
  getUserId(): string | null {
    return this.state.getValue().userId;
  }

  /**
   * Get device token
   */
  getDeviceToken(): string | null {
    return this.state.getValue().deviceToken;
  }

  /**
   * Check if notifications are permitted
   */
  areNotificationsAllowed(): boolean {
    return this.state.getValue().notificationPermission === 'granted';
  }

  /**
   * Reset state (for logout)
   */
  resetState(): void {
    // Unsubscribe device token first
    this.unsubscribeDeviceToken().subscribe({
      next: () => {
        // Reset state to default values
        this.state.next({
          notificationPermission: 'default',
          notificationInteracted: false,
          deviceToken: null,
          isAuthenticated: false,
          userId: null,
          userInfo: null,
          isVerifyCompleted: false
        });
        
        // Clear relevant storage
        this.storageService.removeStorage(StorageNames.DeviceToken);
        this.cookieService.delete('user_id', '/');
      },
      error: () => {
        // Reset state anyway on error
        this.state.next({
          notificationPermission: 'default',
          notificationInteracted: false,
          deviceToken: null,
          isAuthenticated: false,
          userId: null,
          userInfo: null,
          isVerifyCompleted: false
        });
      }
    });
  }

  /**
   * Helper method to update state
   */
  private updateState(partialState: Partial<PlatformState>): void {
    const currentState = this.state.getValue();
    this.state.next({ ...currentState, ...partialState });
  }

  /**
   * Set verification completion status
   */
  setVerificationStatus(isCompleted: boolean): void {
    this.updateState({ isVerifyCompleted: isCompleted });
    this.storageService.saveStorage(StorageNames.IsVerifyCompleted, isCompleted.toString());
  }

  /**
   * Get verification completion status
   */
  isVerificationCompleted(): boolean {
    return this.state.getValue().isVerifyCompleted;
  }

  /**
   * Synchronize user authentication state between cookies and localStorage
   * Ensures that if userId exists in cookie but not userInfo in storage (or vice versa),
   * they are properly synchronized
   * @returns boolean - true if state was synchronized, false if no changes needed
   */
  synchronizeUserState(): boolean {
    let stateChanged = false;
    const currentState = this.state.getValue();
    
    // Get current stored values
    const userIdFromCookie = this.cookieService.get('user_id');
    const userInfoFromStorage = JSON.parse(
      this.storageService.getStorage(StorageNames.UserInfo)
    ) || null;

    console.log('userInfoFromStorage', userInfoFromStorage);
    
    // Case 1: userId exists in cookie but userInfo is not in storage or is incomplete
    if (userIdFromCookie && (!userInfoFromStorage || !userInfoFromStorage.isLoggedIn)) {
      console.log('attempt storage from cookie'); 
      
      try{
        console.log('parsing');

        let json = JSON.parse(userInfoFromStorage); 

        const basicUserInfo = new UserInfoModel(); 
        basicUserInfo.user_id = userIdFromCookie;
        basicUserInfo.isLoggedIn = true;
        basicUserInfo.email = json.emails[0] ?? '';
        basicUserInfo.first_name = json.name ?? '';
        basicUserInfo.last_name = json.family_name ?? '';
        basicUserInfo.phone_no = json.extension_PhoneNumber ?? '';
        basicUserInfo.token = json.idToken ?? '';
        basicUserInfo.birth_date = json.extension_Birthday ?? '';
        
        // Save to storage
        this.storageService.removeStorage(StorageNames.UserInfo);
        this.storageService.saveStorage(StorageNames.UserInfo, JSON.stringify(basicUserInfo));
        
        // Update state
        this.updateState({
          isAuthenticated: true,
          userId: userIdFromCookie,
          userInfo: basicUserInfo
        });
        
        stateChanged = true;
      } catch(exc){ 
        console.log('xtra parse fail ', exc)
        stateChanged = false;
      }  
    }
    
    // Case 2: userInfo exists in storage but userId is not in cookie
    if (userInfoFromStorage && userInfoFromStorage.isLoggedIn && 
        userInfoFromStorage.user_id && !userIdFromCookie) {
      
      console.log('attempt cookie from storage');

      // Set cookie with userId from userInfo
      this.cookieService.delete('user_id', '/');
      this.cookieService.set('user_id', userInfoFromStorage.user_id, 14, '/');
      
      // Update state
      this.updateState({
        isAuthenticated: true,
        userId: userInfoFromStorage.user_id,
        userInfo: userInfoFromStorage
      });
      
      stateChanged = true;
    }
    
    return stateChanged;
  }

  /**
   * Check if the user is authenticated by checking synchronization status first
   * @returns boolean indicating if user is authenticated
   */
  isUserAuthenticated(): boolean {
    // First synchronize in case there are inconsistencies
    this.synchronizeUserState();
    
    // Now check the synchronized state
    const currentState = this.state.getValue();
    return currentState.isAuthenticated;
  }
}

