export const environment = {
  production: false,
 changePasswordUrl:'https://fanmireapp.b2clogin.com/fanmireapp.onmicrosoft.com/B2C_1_Password_Reset/oauth2/v2.0/authorize?p=B2C_1_Password_Reset&client_id=0374ae92-7411-43d8-baeb-f816f232237f&redirect_uri=https://labz.fanmire.com/&scope=openid&response_type=id_token',
  msalConfig: {
    auth: {
      clientId: '0374ae92-7411-43d8-baeb-f816f232237f',
    },
  },
  apiConfig: {
    scopes: ['openid', 'offline_access', 'profile'],
    uri: '',
  },
  b2cPolicies: {
    names: {
      signIn: 'B2C_1_Fanmire_Logins',
      signUp: 'B2C_1_Fanmire_Signup',
      resetPassword: 'b2c_1_reset',
      editProfile: 'b2c_1_edit_profile',
    },
    authorities: {
      signIn: {
        authority:
          'https://fanmireapp.b2clogin.com/fanmireapp.onmicrosoft.com/B2C_1_Fanmire_Logins',
      },
      signUp: {
        authority:
          'https://fanmireapp.b2clogin.com/fanmireapp.onmicrosoft.com/B2C_1_Fanmire_Signup',
      },
      resetPassword: {
        authority:
          'https://fanmireapp.b2clogin.com/fanmireapp.onmicrosoft.com/b2c_1_reset',
      },
      editProfile: {
        authority:
          'https://fanmireapp.b2clogin.com/fanmireapp.onmicrosoft.com/b2c_1_edit_profile',
      },
    },
    authorityDomain: 'fanmireapp.b2clogin.com',
  },
  firebase: {
    apiKey: 'AIzaSyD0zr3R2bUSgBnKQDz7Itq56hQFnZiajUY',
    authDomain: 'fanmirea.firebaseapp.com',
    databaseURL: 'https://fanmirea.firebaseio.com',
    projectId: 'fanmirea',
    storageBucket: 'fanmirea.appspot.com',
    messagingSenderId: '1020738827498',
    appId: '1:1020738827498:web:e3dc87c4d32ad4466e5393',
    measurementId: 'G-C6W1H5PZYC',
    vpaidKey:
      'BPEi2PSanC-lZG4hC6ZKJt51sZ2NaAevYiHcwqIvix7em_aWnxIoZS4DjxUDQcPMvAXpQnbYyGnBTvx_bzzVtHg',
  },
  
  test: 'https://sandbox.fanmire.com',
  dev: 'https://sandbox.fanmire.com',
};
