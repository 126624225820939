import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private $pushNotificationCount: BehaviorSubject<number> = new BehaviorSubject(
    0
  );

  public getPushNotificationCount: Observable<number> =
    this.$pushNotificationCount.asObservable();

  public incementPushNotificationCountBy(numberOfNotifications: number) {
    
    // Get the current count
    const currentCount = this.$pushNotificationCount.getValue();
    // Increment by the specified number
    const newCount = currentCount + numberOfNotifications;
    this.$pushNotificationCount.next(newCount);
  }


  public clearAllNotificationsCount() {
    this.$pushNotificationCount.next(0);
  }

  public getUnreadCount(unReadCount) {
    this.$pushNotificationCount.next(unReadCount);
  }
}