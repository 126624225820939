import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationDataModel } from '../models/notification-data.model';
import { StorageService } from '../services/storage.service';
import { StorageNames } from '../constants/constants';
import { BehaviorSubject } from 'rxjs';
import { RecentNotificationsService } from '../services/recent-notifications.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { PushNotificationService } from '../services/push-notification.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-recent-notifications',
  templateUrl: './recent-notifications.component.html',
  styleUrls: ['./recent-notifications.component.scss'],
})
export class RecentNotificationsComponent implements OnInit {
  @Output() hideRecentNotifications = new EventEmitter<boolean>();
  // Notification data should be coming from localStorage
  subscriptionConfirmed = true;
  $recentNotificationsData = new BehaviorSubject<NotificationDataModel[]>([]);
  getRecentNotificationsData = this.$recentNotificationsData.asObservable();
  recentNotificationsData: NotificationDataModel[] = [];

  constructor(
    public _recentNotificationsService: RecentNotificationsService,
    private _storageService: StorageService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private _notificationsService: PushNotificationService,
    private cookie: CookieService
  ) { }

  ngOnInit(): void {
    this._recentNotificationsService.getRecentNotificationsData.subscribe(
      (res) => {
        this.recentNotificationsData = res || [];
      }
    );
    // this._recentNotificationsService.getRecentNotificationsData
    // Get stored Notifications
    this.recentNotificationsData =
      JSON.parse(
        this._storageService.getStorage(StorageNames.RecentNotificationsData)
      ) ?? [];
    this.$recentNotificationsData.next(this.recentNotificationsData ?? []);
  }

  closeRecentNotifications(isCloseAndClear: boolean) {
    this.hideRecentNotifications.emit(isCloseAndClear);
    let recentNotificationsData =
      JSON.parse(
        this._storageService.getStorage(StorageNames.RecentNotificationsData)
      ) ?? [];
    recentNotificationsData = [];
    this._storageService.saveStorage(
      StorageNames.RecentNotificationsData,
      recentNotificationsData
    );
  }
  removeNotification(index: number) {
    this._recentNotificationsService.removeNotification(index);

  }

  public notificationClicked(
    recentNotification: NotificationDataModel,
    index: number
  ) {
    // Todo:
    // 1. Remove that card which is clicked
    this._recentNotificationsService.removeNotification(index);
    // 2. Redirect to respective Post by received post id i.e. http://localhost:4200/post/671f6898451ac062975373ad
    const contentId = recentNotification.notification.body.split('//')[1];
    if (contentId) {
      const userId = this.cookie.get('user_id');
      this._recentNotificationsService.UpdatePushNotification(recentNotification.messageId,userId).subscribe(data => {
        console.log(data);
        this._notificationsService.getUnreadCount(Number(data));
        this.router.navigate([`/post/${contentId}`]);

      })
    }
  }
  public formatText(text: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(text);
  }
}
