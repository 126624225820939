import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component'; 
import { PlatformAuthGuard, LoginAuthGuard  } from './app-auth.gaurd'; 
import { AppRoutes } from './constants/constants';
import { AuthenticationLoaderComponent } from './authentication-loader/authentication-loader.component';

const routes: Routes = [
  {
    path: AppRoutes.Authenticate,
    component: AuthenticationLoaderComponent,
  },
  { 
    path: 'register/:ref', redirectTo: 'c/:ref', pathMatch: 'full'
  },
  { 
    path: 'c/:ref', 
    loadChildren: () => import('./landingpage/landingpage.module').then(m => m.LandingpageModule), 
  },
  // { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.LandingModule), 
    canActivate: [PlatformAuthGuard], //platform auth guard redirects logged users to dashboard explicitly
  },
  {
    path: '',
    loadChildren: () => import('./platform/platform.module').then(m => m.PlatformdModule)
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }