// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alertContainer {
  position: fixed;
  bottom: 20px;
  right: 10px;
  max-width: 460px;
  margin: 0 auto;
  padding: 20px;
  z-index: 9999;
}

.alertContainer.show-background::before {
  position: fixed;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,aAAA;AACF;;AAGE;EACE,eAAA;EACA,WAAA;EACA,oCAAA;EACA,YAAA;EACA,WAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;AAAJ","sourcesContent":[".alertContainer {\n  position: fixed;\n  bottom: 20px;\n  right: 10px;\n  max-width: 460px;\n  margin: 0 auto;\n  padding: 20px;\n  z-index: 9999;\n}\n\n.alertContainer {\n  &.show-background::before {\n    position: fixed;\n    content: \"\";\n    background-color: rgba(0, 0, 0, 0.6);\n    height: 100%;\n    width: 100%;\n    left: 0;\n    top: 0;\n    z-index: -1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
