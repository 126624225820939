export class UserInfoModel {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_no: string;
  token: string;
  birth_date: string;
  isLoggedIn: boolean;
  account_id: string;
  constructor() {
    this.user_id = '';
    this.email = '';
    this.first_name = '';
    this.last_name = '';
    this.phone_no = '';
    this.token = '';
    this.birth_date = '';
    this.isLoggedIn = false;
    this.account_id = ''
  }
}
