import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AsyncSubject, BehaviorSubject, Observable, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientRoot } from './http-client.service';
import {
  ProfileObject,
  UserDetails,
  SettingsObject,
  WallObject,
  FollowingObject,
  InterestBasicObject,
  GroupObject,
  UserAboutObject,
  UserLanding,
} from '../models/UserModels';

export interface ProfileStatus {
  id?: string;
  profile?: BehaviorSubject<ProfileObject>;
  user?: AsyncSubject<UserDetails>;
  complete: AsyncSubject<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileHttpService extends HttpClientRoot
//  implements OnInit 
 {
  ENDPOINT: string;
  profile: ProfileStatus = {
    id: null, // User ID
    profile: null, // User Profile Object (Raw)
    user: null, // User Details Object
    complete: null, //Completed fetching data
  };

  private profileStatus = new BehaviorSubject<ProfileStatus>(this.profile);
  public $profileStatus = this.profileStatus.asObservable();

  constructor(public cookies: CookieService, HTTP: HttpClient) {
    super(HTTP);
    this.ENDPOINT = this.server + 'users/';
    this.profile.id = this.cookies.get('user_id');
    if (!this.profile.id) {
      this.profile.id = 'guest';
    }
    // console.log(this.profile.id);
    this.getProfile();
  }

  // ngOnInit(): void {
  //   // console.log('profile init');
  //   // this.profile.interest = this.getUserInterests();
  // }

  //test_user = '5da656211ddbc80e7071b085'

  private getProfile(): void {
    // Start retrieving user data
    this.profile.complete = new AsyncSubject<boolean>();
    this.profileStatus.next(this.profile);
    //console.log('fetching profile');
    // console.log(id);
    this.$get(this.ENDPOINT + this.profile.id).subscribe((res) => {
      // console.log('getProfile() in user-http.service', res);
      //Set profile
      this.profile.profile = new BehaviorSubject<ProfileObject>(res); //Set user details
      this.profile.user = new AsyncSubject();
      this.profile.user.next(this.buildDetails(res));
      this.profile.user.complete();
      //set user root data
      this.profile.complete.next(true);
      this.profile.complete.complete();
      this.profileStatus.next(this.profile);
      this.profileStatus.complete();

      // console.log(this.profile);
    });
  }

  private buildDetails(profile: ProfileObject): UserDetails {
    // Do nothin if profile data is null
    if (!profile) {
      return;
    }
    let followings: Array<FollowingObject> = [];
    let interests: Array<InterestBasicObject> = [];
    let entourages: Array<GroupObject> = [];
    let about: UserAboutObject = {
      about: profile.user_about,
      title: profile.user_title,
      city: profile.user_city,
      website: profile.user_website,
    };
    for (let foll of profile.follows) {
      followings.push({
        id: foll.followed_id,
        name: foll.followed_name,
        image: foll.followed_image,
        type: foll.follow_type,
        status: foll.status,
        fid: foll.f_id,
      });
    }
    for (let intr of profile.interests) {
      interests.push({
        interest_id: intr.interest_id,
        active: intr.active,
        interest_name: intr.interest_name,
        interest_image: intr.interest_image,
        display_name: intr.display_name,
        group_id: intr.group_id,
      });
    }
    for (let grp of profile.groups) {
      entourages.push({
        group_id: grp.group_id,
        group_image: grp.group_image,
        group_name: grp.group_name,
        access: grp.access,
        interest: grp.interest,
        influencer_id: grp.influencer_id,
      });
    }

    let details: UserDetails = {
      id: profile.id,
      name: profile.user_name,
      displayName: profile.display_name,
      image: profile.user_image,
      cover: profile.user_cover_image,
      allowBlog: profile.blog_allowed,
      type: profile.user_type,
      createDate: new Date(Date.parse(profile.date_created)),
      postCount: profile.user_post,
      followerCount: profile.fan_count,
      userAbout: about,
      followings: followings,
      entourages: entourages,
      interests: interests,
    };

    return details;
  }

  getUserDetails(user_id: string): Observable<UserDetails> {
    return new Observable<UserDetails>((obs) => {
      this.$get(this.ENDPOINT + user_id).subscribe((res) => {
        obs.next(this.buildDetails(res));
        obs.complete();
      });
    });
  }

  getUserWall(user_id?: string): Observable<WallObject> {
    return new Observable<WallObject>((obs) => {
      const target = user_id ? user_id : this.profile.id;
      this.$get(this.server + this.WALL + target).subscribe((res) => {
        obs.next(res);
        obs.complete();
      });
    });
  }

  getUserLanding(userName: String): Observable<UserLanding> {
    return new Observable<UserLanding>((observer) => {
      this.$get(this.server + 'users/landing/' + userName).subscribe((res) => {
        observer.next(res);
        observer.complete();
      });
    });
  }

  getInterestList(): Observable<any> {
    return new Observable<SettingsObject>((obs) => {
      this.$get(this.server + 'interestlist').subscribe((res) => {
        if (res) {
          obs.next(res);
          obs.complete();
        }
      });
    });
  }

  getBookmarkCollection(collectionId: string): Observable<any> {
    return new Observable<SettingsObject>((obs) => {
      this.$get(
        this.server + 'bookmarks/collections/' + collectionId
      ).subscribe((res) => {
        if (res) {
          obs.next(res);
          obs.complete();
        }
      });
    });
  }

  getBookmarkFeed(profileid: string, collection: boolean): Observable<any> {
    return new Observable<SettingsObject>((obs) => {
      let isCollectionOrFeed = collection ? 'collection' : 'feed';
      this.$get(
        this.server +
          'bookmarks/' +
          profileid +
          '/' +
          isCollectionOrFeed +
          '/' +
          this.profile.id
      ).subscribe((res) => {
        if (res) {
          obs.next(res);
          obs.complete();
        }
      });
    });
  }

  getSettings(): Observable<SettingsObject> {
    return new Observable<SettingsObject>((obs) => {
      this.$get(
        this.ENDPOINT + this.SETTING + 'u/page/' + this.profile.id
      ).subscribe((res) => {
        if (res) {
          obs.next(res);
          obs.complete();
        }
      });
    });
  }

  getUserBlocks(userId: string): Observable<any> {
    return new Observable<any>((obs) => {
      this.$get(
        this.ENDPOINT + 'setting/' + this.profile.id + '/blocks'
      ).subscribe((res) => {
        if (res) {
          obs.next(res);
          obs.complete();
        }
      });
    });
  }

  postUserBlock(
    userId: string,
    blockType: string,
    blockJson: any
  ): Observable<any> {
    return new Observable((obs) => {
      this.$post(
        this.server + 'setting/' + userId + '/blocks/' + blockType,
        blockJson
      ).subscribe((res) => {
        if (res) {
          obs.next(res);
          obs.complete();
        }
      });
    });
  }

  getReporting(): Observable<any> {
    return new Observable<any>((obs) => {
      this.$get(this.ENDPOINT + 'report/' + this.profile.id).subscribe(
        (res) => {
          if (res) {
            obs.next(res);
            obs.complete();
          }
        }
      );
    });
  }

  postProfileMedia(file: File, type: string): Observable<boolean> {
    if (file === undefined) {
      return new Observable((obs) => {
        obs.next(false);
        obs.complete();
      });
    }
    return new Observable((obs) => {
      const data = new FormData();
      data.append('file', file);
      this.$post(
        this.ENDPOINT + this.MEDIA_UPLOAD + this.profile.id + '/' + type,
        data
      ).subscribe((res) => {
        if (res) {
          this.refreshUserDetails();
          obs.next(true);
          obs.complete();
        } else {
          obs.next(false);
          obs.complete();
        }
      });
    });
  }

  postUserInterest(interestUpdate: any): Observable<any> {
    return new Observable((obs) => {
      this.$post(
        this.server + 'interest/' + this.profile.id,
        interestUpdate
      ).subscribe((res) => {
        if (res) {
          this.refreshUserDetails();
          obs.next(res);
          obs.complete();
        }
      });
    });
  }

  refreshUserDetails(): Observable<ProfileStatus> {
    this.getProfile();
    return this.$profileStatus;
  }
}
