import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClientRoot } from './core/http/http-client.service';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from 'firebase/messaging';
import { environment } from 'src/environments/environment';
import { NotificationDataModel } from './core/models/notification-data.model';
import { StorageService } from './services/storage.service';
import { StorageNames } from './constants/constants';
import { UserInfoModel } from './core/models/user-info.model';
import { Adb2cService } from './services/adb2c.service';
import { RecentNotificationsService } from './services/recent-notifications.service';
import { CookieService } from 'ngx-cookie-service';
import { PushNotificationService } from './services/push-notification.service';
import { PostsService } from './services/posts.service';
import { interval as intervalFromRx, takeWhile } from 'rxjs';
import { PlatformStateService } from './core/services/platform-state.service';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  shouldShowRecentNotifications = false;
  showBackground = false;
  notifications = [];
  channel!: BroadcastChannel;
  userId: string;
  isNotificationPopUpInteracted = false;
  isNotificationGratedByUser = false;
  isCheckingSession = true; // Flag to track session check in progress
  
  constructor(
    private router: Router,
    private base: HttpClientRoot,
    private _storageService: StorageService,
    private _adb2cService: Adb2cService,
    private _recentNotificationsService: RecentNotificationsService,
    private cookieService: CookieService,
    private posts: PostsService,
    private _notificationsService: PushNotificationService,
    private platformState: PlatformStateService
  ) {
    this.platformState.notificationInteracted$.subscribe(interacted => {
      this.isNotificationPopUpInteracted = interacted;
    });
    
    this.platformState.notificationPermission$.subscribe(permission => {
      this.isNotificationGratedByUser = permission === 'granted';
    });
    
    this.router.events.subscribe((event) => {
      //track pageviews in analytics if not in test mode
      if (base.dev == false) {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-146943594-2', {
            page_path: event.urlAfterRedirects,
          });
        }
      }
    });
  }
  
  ngOnInit(): void {  

    // First, check for existing session before doing anything else
    this.checkExistingSession().then(() => {
      // Set up the notification channel
      this.channel = new BroadcastChannel('firebase-messages');
      this.channel.addEventListener('message', (event) => {
        this._notificationsService.handleIncomingMessage(event.data);
      });
      
      // Now handle notifications if user is logged in
      if (this.platformState.isUserAuthenticated()) {
        this.updateShowRecentNotifications();
        this.checkUserIdAndFetchNotifications();
      }
      
      // Subscribe to notification changes
      this._recentNotificationsService.getRecentNotificationsData.subscribe(
        (res) => {
          if (res.length < 1) {
            this.shouldShowRecentNotifications = false;
            this.showBackground = false;
          }
        }
      );
    });
  }

  /**
   * Check for existing session first before allowing MSAL redirects
   * This prevents unnecessary redirects if we already have a valid session
   */
  async checkExistingSession(): Promise<void> {
    return new Promise<void>((resolve) => {
      // First check if platform state already shows authenticated
      if (this.platformState.isUserAuthenticated()) {
        console.log('User already authenticated via platform state');
        this.isCheckingSession = false;
        resolve();
        return;
      }
      
      // If not, try to synchronize user state between localStorage and cookies
      const stateChanged = this.platformState.synchronizeUserState();
      
      if (stateChanged && this.platformState.isUserAuthenticated()) {
        console.log('User authentication recovered via state synchronization');
        this.isCheckingSession = false;
        resolve();
        return;
      }
      
      // If synchronization didn't work, try more aggressive token recovery
      this._adb2cService.attemptTokenRecovery().subscribe({
        next: (recovered) => {
          if (recovered) {
            console.log('User session recovered via token recovery');
          } else {
            console.log('Session recovery failed, proceeding with normal flow');
          }
          this.isCheckingSession = false;
          resolve();
        },
        error: () => {
          console.log('Error during session recovery, proceeding with normal flow');
          this.isCheckingSession = false;
          resolve();
        }
      });
    });
  }

  updateNotificationInteractionStatus($event){
    console.log($event)
  }

  hideNotifications(isClearAndClose: boolean) {
    if (isClearAndClose) {
      // If it is clear, mark notifications read and close
      // Todo: mark notifications read
      console.log('Notifications marked read');
      this.showBackground = false;
    }
    // Close the Notifications container
    this.shouldShowRecentNotifications = false;
  }

  private updateShowRecentNotifications() {
    // Only check for notifications if user is authenticated
    if (!this.platformState.isUserAuthenticated()) {
      this.shouldShowRecentNotifications = false;
      this.showBackground = false;
      return;
    }
    
    // Get stored Notifications
    let recentNotificationsData: NotificationDataModel[] = JSON.parse(
      this._storageService.getStorage(StorageNames.RecentNotificationsData) || '[]'
    );
    
    // Check if data exists
    if (recentNotificationsData && recentNotificationsData.length > 0) {
      this.shouldShowRecentNotifications = true;
      this.showBackground = true;
    } else {
      this.shouldShowRecentNotifications = false;
      this.showBackground = false;
    }
  }

  private updateAndMaintainNotification(payload: MessagePayload) {

    // Check if notification is generated by logged in user
    // Then don't display notification

    const pushNotificationGeneratorId =
      payload.notification.title?.split('//')[1];
    const loggedInUserId = this.cookieService.get('user_id');
    if (
      pushNotificationGeneratorId &&
      loggedInUserId &&
      pushNotificationGeneratorId == loggedInUserId
    ) {
      // Since Notification is generated by the same use
      // do nothing
      return;
    }
    this._notificationsService.incementPushNotificationCountBy(1);
    this._recentNotificationsService.addMessage(payload);
    // Once all save update weather to show or hide recent notifications
    this.updateShowRecentNotifications();
  }

  private checkUserIdAndFetchNotifications(): void {
    // Create an interval to check every 500ms
    intervalFromRx(500)
      .pipe(
        takeWhile(() => !this.cookieService.get('user_id')) // Stop once user_id is found
      )
      .subscribe({
        next: () => {
          /* Waiting for user_id */
        },
        complete: () => {
          // Call the API when user_id is available
          this.getNotificationCount();
        },
      });
  }

  private getNotificationCount(retries = 5, delay = 500): Promise<void> {
    // Skip notifications if user isn't authenticated yet or hasn't granted permission
    if (!this.platformState.isUserAuthenticated() || !this.isNotificationGratedByUser) {
      console.log('User not authenticated or notifications not enabled, skipping notification fetch');
      return Promise.resolve();
    }
    
    this.userId = this.cookieService.get('user_id');

    if (!this.userId && retries > 0) {
      // Retry after delay if user ID is not set yet
      return new Promise(resolve => {
        setTimeout(() => {
          this.getNotificationCount(retries - 1, delay).then(resolve);
        }, delay);
      });
    }

    if (!this.userId) {
      console.error('User ID not found after retries.');
      return Promise.resolve();
    }

    // Call your API now that the user ID is available
    return new Promise(resolve => {
      this.posts.getNotifications(this.userId).subscribe({
        next: (data) => {
          if (!data) {
            resolve();
            return;
          }
          
          if (data.FCMHistoryList.length > 0) {
            this._notificationsService.getUnreadCount(data.count);
            const transformedNotifications = data.FCMHistoryList.map(
              (notification) => ({
                collapseKey: '',
                from: '',
                messageId: notification.FCM_Id,
                is_read: notification.is_read,
                notification: {
                  title: notification.notification_title,
                  body: notification.notification_body,
                },
              })
            );

            transformedNotifications.forEach((notification) => {
              if (!notification.is_read) {
                this._recentNotificationsService.addMessage(notification);
                this.shouldShowRecentNotifications = true;
                this.showBackground = true;
              }
            });
          }
          resolve();
        },
        error: () => {
          console.error('Error fetching notifications');
          resolve();
        }
      });
    });
  }
}
