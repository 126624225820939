import { Injectable, isDevMode } from '@angular/core';
import { PlatformStateService } from '../core/services/platform-state.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  dev = true;

  PRODENDPOINT = 'https://ap.fanmire.com/api/';
  TESTENDPOINT = environment.dev + '/api/';
  USER = 'users/';
  WALL = 'wall/';
  POST = 'feed/';
  COMMENTS = 'comments/';
  INTEREST_LIST = 'interestlist/';
  INTEREST = 'inerest/';
  MEDIA_UPLOAD = 'media/';
  MEDIA_JSON_UPLOAD = 'media/json/';
  CONTENT_UPLOAD = 'content/';

  PROFILE_PIC = 'profile/';
  COVER_PIC = 'cover/';

  SETTING = 'setting/';

  server: string;

  constructor() {
    if (this.dev) {
      this.server = this.TESTENDPOINT;
    } else {
      this.server = this.PRODENDPOINT;
    }
  }
}
