import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  IdTokenClaims,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  PromptValue,
  RedirectRequest,
  SsoSilentRequest,
} from '@azure/msal-browser';
import { CookieService } from 'ngx-cookie-service';
import { filter, Observable, Subject, takeUntil, switchMap, catchError, throwError, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfoModel } from '../core/models/user-info.model';
import { StorageService } from './storage.service';
import { AppRoutes, SocialDomains, StorageNames } from '../constants/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../home/auth.service';
import { ApiEndpoints } from '../constants/api.endpoint';
import { PlatformStateService } from '../core/services/platform-state.service';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
  extension_Birthday?: string;
  idp_access_token?: string;
  name?: string;
  family_name?: string;
  extension_PhoneNumber?: string;
};

@Injectable({
  providedIn: 'root',
})
export class Adb2cService implements OnDestroy {
  private msalStatus: string;
  // loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private cookie: CookieService,
    private _storageService: StorageService,
    private httpClient: HttpClient,
    private _authService: AuthService,
    private platformState: PlatformStateService
  ) {}

  public initiateMsalAuthentication() {
    if (
      this.msalStatus === undefined ||
      this.msalStatus === InteractionStatus.None
    ) {
      this.msalAccountAdded();
      this.msalInProgress();
      this.msalSuccess();
      this.msalFailed();
    }
  }

  public tempTrySuccess() {
    if (
      this.msalStatus === undefined ||
      this.msalStatus === InteractionStatus.None
    ) {
      this.msalSuccess();
      this.msalFailed();
    }
  }

  private msalAccountAdded() {
    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          this.router.navigate(['/login']);
        }
      });
  }

  private msalInProgress() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => {
          this.msalStatus = status;
          return status === InteractionStatus.None;
        }),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        // this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  private checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }
  //handle successful login
  private msalSuccess() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        switchMap(async (result: EventMessage) => {
          console.log('succ res: ', result);

          const payload = result.payload as AuthenticationResult;
          const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
          const isGoogleSignIn = idtoken.idp && idtoken.idp == SocialDomains.Google;
          
          const userInfoData = new UserInfoModel();
          userInfoData.account_id = idtoken.oid ?? '';
          userInfoData.email = idtoken.emails[0] ?? '';
          userInfoData.first_name = idtoken.name ?? '';
          userInfoData.last_name = idtoken.family_name ?? '';
          userInfoData.phone_no = idtoken.extension_PhoneNumber ?? '';
          userInfoData.token = payload.idToken ?? '';
          userInfoData.birth_date = idtoken.extension_Birthday ?? '';
          userInfoData.isLoggedIn = true;
          const authority = payload.authority;
          // this.msalService.instance.setActiveAccount(payload.account);

          const registerationData = {
            user_name: 'fan' + idtoken.oid.substring(0, 8),
            user_password: '',
            first_name: userInfoData.first_name,
            last_name: userInfoData.last_name,
            user_mobile: userInfoData.phone_no,
            user_details: {
              user_email: userInfoData.email,
              birth_date: userInfoData.birth_date,
            },
            referrer: '',
            user_type: 'fan',
          };

          try {
            // For Social Login+SignIn or SignUp
            if (isGoogleSignIn || authority?.toLowerCase().includes(environment.b2cPolicies.names.signUp.toLowerCase())) {
              const userId = await firstValueFrom(this.registerUserIntoDb(registerationData)).catch(error => {
                console.error('Registration error:', error);
                return null;
              });

              if (userId && userId !== "failed to create") {
                userInfoData.user_id = userId;
                this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
              }

              if (isGoogleSignIn) {
                this._storageService.saveStorage(StorageNames.isGoogleSignIn, true);
                if (idtoken.idp_access_token) {
                  this._storageService.saveStorage(StorageNames.idpAccessToken, idtoken.idp_access_token);
                }
              }
            } else {
              let userid = await firstValueFrom(this.getUseIdFromDbAndSaveInStorage(userInfoData, !isGoogleSignIn)).catch(error => {
                console.error('User ID retrieval error:', error);
                return null;
              });

              //no user found - try to create one
              if (!userid || userid === "No") {
                console.log('no user- create');
                userid = await firstValueFrom(this.registerUserIntoDb(registerationData)).catch(error => {
                  console.error('Registration error:', error);
                  return null;
                });

                if (userid && userid !== 'failed to create') {
                  console.log('created user: ', userid);
                  userInfoData.user_id = userid;
                  this.cookie.set(StorageNames.UserId, userid, 20, '/');
                  this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
                  this.platformState.setAuthenticationState(true, userid, userInfoData);
                }
              } else {
                console.log('id found ', userid)
                userInfoData.user_id = userid;
                this.cookie.set(StorageNames.UserId, userid, 20, '/');
                this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
                this.platformState.setAuthenticationState(true, userid, userInfoData);
              }

              if (userid) {
                let usercompleted = await firstValueFrom(this.handleUserEmailLookup(userInfoData, userid, !isGoogleSignIn)).catch(error => {
                  console.error('User email lookup error:', error);
                  return null;
                });

                if (usercompleted) { 
                  this.router.navigate([`/explore`]);
                } else {
                  this.router.navigate([`/interest`]);
                }
              }
            }
          } catch (error) {
            console.error('Error in msalSuccess:', error);
          }

          return result;
        }),
        catchError(error => {
          console.error('Error in msalSuccess:', error);
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  signUp() {
    let signUpFlowRequest: RedirectRequest | PopupRequest = {
      authority: environment.b2cPolicies.authorities.signUp.authority,
      scopes: [...environment.apiConfig.scopes],
    };
    this._storageService.saveStorage(StorageNames.IsVerifyCompleted, false);
    this.login(signUpFlowRequest);
  }

  private msalFailed() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority:
              environment.b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };
          this.login(resetPasswordFlowRequest);
        } else if (
          result.error &&
          result.error.message.includes('AADB2C90091')
        ) {
          // Handle cancellation (User canceled the flow)
          this.router.navigate(['/']);
        } else {
          // Other errors (fallback)
          console.error('Login error:', result.error);
          this.router.navigate(['/error']);
        }
      });
  }

  logout() { 
    // Reset platform state
    this.platformState.resetState();

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalService.logoutRedirect();
      const isGoogleSignIn = this._storageService.getStorage(
        StorageNames.isGoogleSignIn
      );
      let newTab = null;

      if (isGoogleSignIn && isGoogleSignIn === 'true') {
        //wrong approach: This was logging users out from their google account across the whole browser not just fanmire
        // In order to Logout from Social Login Google, 
        // we need to open https://accounts.google.com/Logout
        //newTab = window.open('https://accounts.google.com/Logout', '_blank');
        // Attempt to focus on the new tab (this may not work in all browsers)
         
      }
    } 
  }

  editProfile() {
    let editProfileFlowRequest: RedirectRequest | PopupRequest = {
      authority: environment.b2cPolicies.authorities.editProfile.authority,
      scopes: [],
    };

    this.login(editProfileFlowRequest);
  }

  private getADAccountId(userInfo: UserInfoModel): Observable<any> {
    const payload = {
      user_name: userInfo.email.toLocaleLowerCase(),
    };
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');

    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    console.log("run LogAD");

    //this returns 'No' if no user account is found
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.LogADUser}`,
      payload,
      options
    );
  }

  public getUserByEmail(userInfo: UserInfoModel): Observable<any> {
    const payload = {
      user_email: userInfo.email.toLocaleLowerCase(),
    };
    const headersData = new HttpHeaders({
      Authorization: `Bearer ${userInfo.token}`,
      'Content-Type': 'application/json',
    });
    const options = {
      headers: headersData,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.GetUserByEmail}`,
      payload,
      options
    );
  }
  public saveDeviceToken(deviceToken: string, userId: string): Observable<any> {
    console.log('userId in saveDeviceToken()', userId);
    const headersData = new HttpHeaders({
      'Content-Type': 'application/json',
      deviceId: deviceToken,
    });
    const options = {
      headers: headersData,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.UpdateDevieToken}/${userId}`,
      {},
      options
    );
  }

  registerUserIntoDb(registerationData): Observable<string> { 
    return new Observable<string>(observer => {
      let created_id = '';

      // Call API to register user in MongoDb as well
      this._authService
        .register(registerationData, registerationData.user_type)
        .subscribe((result) => {
          //if registration success
          if (result !== 'exist') {

            //this should return a user id from db to save
            console.log('register result: ', result); 
            created_id = result; 
            
          } else {
            //registration failed error
            console.log('User is already registered!'); 
          } 
        });

      observer.next(created_id);
      observer.complete();
    })
  }

  private handleUserEmailLookup(userInfoData: UserInfoModel, dbUserId: string, isRedirectRequired: boolean = false): Observable<string | null> {
    return new Observable<any | null>(observer => {
        this.getUserByEmail(userInfoData).subscribe({
            next: (res) => {
                if (res) {

                  console.log("lookupresult: ", res);
                  
                  //check if profile is completed
                  if(res.user_profile) {
                    this.platformState.setVerificationStatus(true);
                    observer.next("complete");
                  } else{
                    observer.next("incomplete");
                  } 

                } else {
                  console.log('User email lookup returned no results');
                  observer.next(null);
                }
                observer.complete();
            },
            error: (err) => {
                console.error('Get user by email error:', err);
                observer.next(null);
                observer.complete();
            }
        });
    });
  }

  getUseIdFromDbAndSaveInStorage(userInfoData: UserInfoModel, isRedirectRequired: boolean = false): Observable<string | null> {
    return new Observable<string | null>(observer => {
        // Get User_id from API and save in cookie
        this.getADAccountId(userInfoData).subscribe({
            next: (dbUserId) => {
                // Check If user is not registered in Mongo db
                if (dbUserId === 'No') {
                    console.log("couldn't find user account"); 


                } else {
                    //set user_id cookie
                    this.cookie.set(StorageNames.UserId, dbUserId, 20, '/');

                    //try to get device token
                    const currentToken = this._storageService.getStorage(StorageNames.DeviceToken);
                    if (currentToken) {
                        this.saveDeviceToken(currentToken, dbUserId).subscribe();
                    } 
                }
            },
            error: (err) => {
                console.error('Get AD account error:', err);
                observer.next(null);
                observer.complete();
            }
        });
    });
  }

  public unSubscribeDeviceToken(
    deviceToken: string,
    userId: string
  ): Observable<any> {
    const headersData = new HttpHeaders({
      'Content-Type': 'application/json',
      deviceId: deviceToken,
    });
    const options = {
      headers: headersData,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.UnsubscribeDeviceToken}/${userId}`,
      {},
      options
    );
  }

  public isUserLoggedIn() {
    const token = this.msalService.instance.getActiveAccount()?.idToken;
    if (token && this.msalService.instance.getAllAccounts().length > 0) {
      return true;
    } else {
      return false;
    }
  }
  public checkLoginAndRedirect() {
    const userInfo: UserInfoModel = JSON.parse(
      this._storageService.getStorage(StorageNames.UserInfo)
    );
    if (this.isUserLoggedIn()) {
      this.router.navigate([`/${AppRoutes.Explore}`]);
    } else if (
      this.msalStatus === InteractionStatus.None ||
      this.msalStatus == undefined
    ) {
      this.router.navigate([`/`]);
    } else {
      this.router.navigate([`/${AppRoutes.Authenticate}`]);
    }
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  // Add a more robust token recovery method
  public attemptTokenRecovery(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      // First check if we actually have accounts
      const accounts = this.msalService.instance.getAllAccounts();
      
      if (accounts.length > 0) {
        // We have accounts, try to set the active account
        this.msalService.instance.setActiveAccount(accounts[0]);
        
        // Try to silently acquire a token to verify it works
        const silentRequest = {
          scopes: [...environment.apiConfig.scopes],
          account: accounts[0],
          forceRefresh: true // Force refresh to ensure we get a fresh token
        };
        
        this.msalService.acquireTokenSilent(silentRequest).subscribe({
          next: (response) => {
            // Token acquisition successful
            // Extract user information from the response
            const idTokenClaims = response.idTokenClaims as IdTokenClaimsWithPolicyId;
            
            // Create user info object
            const userInfoData = new UserInfoModel();
            userInfoData.account_id = idTokenClaims.oid ?? '';
            userInfoData.email = idTokenClaims.emails[0] ?? '';
            userInfoData.first_name = idTokenClaims.name ?? '';
            userInfoData.last_name = idTokenClaims.family_name ?? '';
            userInfoData.phone_no = idTokenClaims.extension_PhoneNumber ?? '';
            userInfoData.token = response.idToken ?? '';
            userInfoData.birth_date = idTokenClaims.extension_Birthday ?? '';
            userInfoData.isLoggedIn = true;
            
            // Save user info
            this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
            
            // Get user ID from DB
            this.getADAccountId(userInfoData).subscribe({
              next: (dbUserId) => {
                if (dbUserId && dbUserId !== 'No') {
                  this.cookie.set(StorageNames.UserId, dbUserId, 20, '/');
                  // Update platform state
                  this.platformState.setAuthenticationState(true, dbUserId, userInfoData);
                  observer.next(true);
                  observer.complete();
                } else {
                  // We couldn't get a user ID
                  observer.next(false);
                  observer.complete();
                }
              },
              error: () => {
                observer.next(false);
                observer.complete();
              }
            });
          },
          error: () => {
            // Token acquisition failed, we need to redirect to login
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        // No accounts available, recovery not possible without full login
        observer.next(false);
        observer.complete();
      }
    });
  }

  // Enhanced method to check token validity
  public checkTokenValidity(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const account = this.msalService.instance.getActiveAccount();
      
      if (!account) {
        observer.next(false);
        observer.complete();
        return;
      }
      
      // Token expiration check (if possible)
      const idTokenClaims = account.idTokenClaims;
      if (idTokenClaims && idTokenClaims['exp']) {
        const expirationTime = idTokenClaims['exp'] * 1000; // Convert to milliseconds
        const currentTime = Date.now();
        
        if (currentTime > expirationTime) {
          // Token has expired
          observer.next(false);
          observer.complete();
          return;
        }
      }
      
      // Also check if user info is available in storage
      const userInfo = JSON.parse(this._storageService.getStorage(StorageNames.UserInfo) || 'null');
      const userId = this.cookie.get('user_id');
      
      if (!userInfo || !userId) {
        observer.next(false);
        observer.complete();
        return;
      }
      
      observer.next(true);
      observer.complete();
    });
  }

  // Explicit method for sign-up flow
  loginWithSignUpFlow(): Observable<void> {
    const request: RedirectRequest = {
      authority: environment.b2cPolicies.authorities.signUp.authority,
      scopes: [...environment.apiConfig.scopes]
    };
    this._storageService.saveStorage(StorageNames.IsVerifyCompleted, false);
    return this.msalService.loginRedirect(request);
  }

  // Explicit method for sign-in flow
  loginWithSignInFlow(): Observable<void> {
    const request: RedirectRequest = {
      authority: environment.b2cPolicies.authorities.signIn.authority,
      scopes: [...environment.apiConfig.scopes]
    };
    return this.msalService.loginRedirect(request);
  }

  // Explicit method for password reset flow
  requestPasswordReset(): Observable<void> {
    const request: RedirectRequest = {
      authority: environment.b2cPolicies.authorities.resetPassword.authority,
      scopes: []
    };
    return this.msalService.loginRedirect(request);
  }

  // Handle token refresh to prevent redirect loops
  refreshTokenSilently(): Observable<AuthenticationResult> {
    const activeAccount = this.msalService.instance.getActiveAccount();
    
    if (!activeAccount) {
      return new Observable(observer => {
        observer.error(new Error('No active account found'));
        observer.complete();
      });
    }
    
    const silentRequest: SsoSilentRequest = {
      scopes: [...environment.apiConfig.scopes],
      account: activeAccount,
      authority: environment.b2cPolicies.authorities.signIn.authority
    };
    
    return this.msalService.ssoSilent(silentRequest);
  }

  // Safer version of login to prevent redirect loops
  loginSafe(userFlowRequest?: RedirectRequest | PopupRequest): Observable<void | AuthenticationResult> {
    // Store the current URL to return to after login
    const currentUrl = this.router.url;
    this._storageService.saveStorage('loginRedirectUrl', currentUrl);

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        return this.msalService.loginPopup({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as PopupRequest);
      } else {
        return this.msalService.loginPopup(userFlowRequest);
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        return this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        return this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }
}
