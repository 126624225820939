import { Injectable } from '@angular/core';
import { BaseService } from './base.service'
import { basename } from 'path';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BaseService{

  ENDPOINT: string;   

  constructor() { 
    super();
    this.ENDPOINT = this.server;
  }
   
}
