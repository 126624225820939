import { MessagePayload } from 'firebase/messaging';

export class NotificationDataModel {
  from: string;
  collapseKey: string;
  messageId: string;
  notification: Notification;
  constructor(payload?: MessagePayload) {
    this.from = payload.from ?? '';
    this.collapseKey = payload.collapseKey ?? '';
    this.messageId = payload.messageId ?? '';
    this.notification =
      (payload.notification as Notification) ?? new Notification();
  }
}

export class Notification {
  title: string;
  body: string;
  image: string;
  constructor() {
    this.title = '';
    this.body = '';
    this.image = '';
  }
}
