import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PlatformStateService } from '../core/services/platform-state.service';
import { StorageService } from './storage.service';
import { StorageNames } from '../constants/constants';
import { NotificationDataModel } from '../core/models/notification-data.model';
import { 
  getMessaging, 
  getToken, 
  MessagePayload, 
  onMessage 
} from 'firebase/messaging';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiEndpoints } from '../constants/api.endpoint';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  // Notification count and data
  private $pushNotificationCount = new BehaviorSubject<number>(0);
  private $recentNotifications = new BehaviorSubject<NotificationDataModel[]>([]);

  // Public observables
  public getPushNotificationCount: Observable<number> = this.$pushNotificationCount.asObservable();
  public getRecentNotifications: Observable<NotificationDataModel[]> = this.$recentNotifications.asObservable();

  constructor(
    private platformState: PlatformStateService,
    private storageService: StorageService,
    private httpClient: HttpClient
  ) {
    // Initialize notification data from storage
    const storedNotifications: NotificationDataModel[] = JSON.parse(
      this.storageService.getStorage(StorageNames.RecentNotificationsData) || '[]'
    );
    this.$recentNotifications.next(storedNotifications);
    
    // Subscribe to authentication state to initialize Firebase when appropriate
    this.platformState.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated && this.platformState.areNotificationsAllowed()) {
        this.setupFirebaseMessaging();
      }
    });
  }

  /**
   * Initialize Firebase messaging
   */
  public setupFirebaseMessaging(): void {
    try {
      const messaging = getMessaging();
      
      // Get Firebase token
      getToken(messaging, { vapidKey: environment.firebase.vpaidKey })
        .then(token => {
          if (token) {
            // Save token in platform state
            this.platformState.setDeviceToken(token);
          }
        })
        .catch(error => console.error('Firebase token error:', error));
      
      // Set up message handler
      onMessage(messaging, (payload: MessagePayload) => {
        this.handleIncomingMessage(payload);
      });
      
      // Set up broadcast channel for background notifications
      const channel = new BroadcastChannel('firebase-messages');
      channel.addEventListener('message', (event) => {
        this.handleIncomingMessage(event.data);
      });
    } catch (error) {
      console.error('Firebase messaging setup error:', error);
    }
  }

  /**
   * Handle incoming notification
   */
  public handleIncomingMessage(payload: MessagePayload): void {
    // Skip if notification comes from current user
    const pushNotificationGeneratorId = payload.notification?.title?.split('//')[1];
    const loggedInUserId = this.platformState.getUserId();
    
    if (pushNotificationGeneratorId && 
        loggedInUserId && 
        pushNotificationGeneratorId === loggedInUserId) {
      return;
    }
    
    // Increment notification count
    this.incementPushNotificationCountBy(1);
    
    // Add to recent notifications
    this.addNotificationToRecentList(payload);
  }
  
  /**
   * Add notification to recent list
   */
  public addNotificationToRecentList(payload: MessagePayload): void {
    const notificationData = new NotificationDataModel(payload);
    
    // Get current notifications
    const currentNotifications = this.$recentNotifications.getValue();
    
    // Add new notification to the beginning
    const updatedNotifications = [notificationData, ...currentNotifications]
      // Remove duplicates
      .filter((notification, index, self) => 
        index === self.findIndex(n => n.messageId === notification.messageId))
      // Keep only the 3 most recent
      .slice(0, 3);
    
    // Update state and storage
    this.$recentNotifications.next(updatedNotifications);
    this.storageService.saveStorage(
      StorageNames.RecentNotificationsData, 
      JSON.stringify(updatedNotifications)
    );
  }

  /**
   * Increment notification count
   */
  public incementPushNotificationCountBy(numberOfNotifications: number): void {
    const currentCount = this.$pushNotificationCount.getValue();
    this.$pushNotificationCount.next(currentCount + numberOfNotifications);
  }

  /**
   * Clear all notifications count
   */
  public clearAllNotificationsCount(): void {
    this.$pushNotificationCount.next(0);
  }

  /**
   * Set unread count (from API)
   */
  public getUnreadCount(unReadCount: number): void {
    this.$pushNotificationCount.next(unReadCount);
  }

  /**
   * Remove notification at specific index
   */
  public removeNotification(index: number): void {
    const currentNotifications = this.$recentNotifications.getValue();
    
    if (index >= 0 && index < currentNotifications.length) {
      const updatedNotifications = [...currentNotifications];
      updatedNotifications.splice(index, 1);
      
      this.$recentNotifications.next(updatedNotifications);
      this.storageService.saveStorage(
        StorageNames.RecentNotificationsData, 
        JSON.stringify(updatedNotifications)
      );
    }
  }

  /**
   * Mark notification as read
   */
  public markNotificationAsRead(contentId: string): Observable<any> {
    const userId = this.platformState.getUserId();
    
    if (!userId || !contentId) {
      return of(null);
    }
    
    const headersData = new HttpHeaders({
      'Content-Type': 'application/json',
      messageId: contentId
    });
    
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.UpdateFCMHistory}/${userId}`,
      {},
      { headers: headersData }
    );
  }

  /**
   * Fetch notifications from API
   */
  public fetchNotifications(): void {
    const userId = this.platformState.getUserId();
    
    if (!userId) {
      return;
    }
    
    // Implement API call based on your getNotificationCount implementation
    // This should populate both notification count and recent notifications
  }
}