import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClientRoot } from './core/http/http-client.service';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from 'firebase/messaging';
import { environment } from 'src/environments/environment';
import { NotificationDataModel } from './models/notification-data.model';
import { StorageService } from './services/storage.service';
import { StorageNames } from './constants/constants';
import { UserInfoModel } from './models/user-info.model';
import { Adb2cService } from './services/adb2c.service';
import { RecentNotificationsService } from './services/recent-notifications.service';
import { CookieService } from 'ngx-cookie-service';
import { PushNotificationService } from './services/push-notification.service';
import { PostsService } from './services/posts.service';
import { interval as intervalFromRx, takeWhile } from 'rxjs';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  shouldShowRecentNotifications = false;
  showBackground = false;
  notifications = [];
  channel!: BroadcastChannel;
  userId: string;
  constructor(
    private router: Router,
    private base: HttpClientRoot,
    private _storageService: StorageService,
    private _adb2cService: Adb2cService,
    private _recentNotificationsService: RecentNotificationsService,
    private cookieService: CookieService,
    private pushNotificationService: PushNotificationService,
    private posts: PostsService,
    private _notificationsService: PushNotificationService
  ) {
    this.router.events.subscribe((event) => {
      //track pageviews in analytics if not in test mode
      if (base.dev == false) {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-146943594-2', {
            page_path: event.urlAfterRedirects,
          });
        }
      }
    });
  }
  ngOnInit(): void {
    // get the background notification threw created channel

    this.channel = new BroadcastChannel('firebase-messages');
    this.channel.addEventListener('message', (event) => {
      this.pushNotificationService.incementPushNotificationCountBy(1);
      this.updateAndMaintainNotification(event.data);
    });
    // Required for getting token of device to send Device Level notification
    // as 1 user can have logged in from multiple devices i.e. Mobile and Web
    this.requestPermission();
    this.updateShowRecentNotifications();
    this.checkUserIdAndFetchNotifications();
    this._recentNotificationsService.getRecentNotificationsData.subscribe(
      (res) => {
        if (res.length < 1) {
          this.shouldShowRecentNotifications = false;
          this.showBackground = false;
        }
      }
    );
  }

  private requestPermission() {
    const permission = Notification.requestPermission();
    permission
      .then((res) => {
        console.log('Push Notification Permission: ', res);
      })
      .catch((res) => {
        console.log('Push Notification Permission: ', res);
      });

    const messaging = getMessaging();

    getToken(messaging, { vapidKey: environment.firebase.vpaidKey }).then(
      (currentToken) => {
        if (currentToken) {
          console.log('You have the token');
          console.log(currentToken);
          this._storageService.saveStorage(
            StorageNames.DeviceToken,
            currentToken
          );
          let userId = this.cookieService.get('user_id');
          if (userId) {
            // save device token to db
            this._adb2cService
              .saveDeviceToken(currentToken, userId)
              .subscribe((res) => {
                console.log('token saved in API res', res);
              });
          }
        } else {
          console.log('No token, there is some problem');
        }
      }
    );

    onMessage(messaging, (payload: MessagePayload) => {
      console.log('onMessage', payload);
      this.pushNotificationService.incementPushNotificationCountBy(1);

      this.updateAndMaintainNotification(payload);
      //
    });
  }

  hideNotifications(isClearAndClose: boolean) {
    if (isClearAndClose) {
      // If it is clear, mark notifications read and close
      // Todo: mark notifications read
      console.log('Notifications marked read');
      this.showBackground = false;
    }
    // Close the Notifications container
    this.shouldShowRecentNotifications = false;
  }

  private updateShowRecentNotifications() {
    const userInfo: UserInfoModel = JSON.parse(
      this._storageService.getStorage(StorageNames.UserInfo)
    );
    // console.log('userInfo.isLoggedIn', userInfo?.isLoggedIn);

    // debugger;
    if (userInfo && userInfo.isLoggedIn) {
      // Get stored Notifications
      let recentNotificationsData: NotificationDataModel[] = JSON.parse(
        this._storageService.getStorage(StorageNames.RecentNotificationsData)
      );
      // Check if data is null assign empty array
      if (recentNotificationsData && recentNotificationsData.length > 0) {
        this.shouldShowRecentNotifications = true;
        this.showBackground = true;
      }
    } else {
      this.shouldShowRecentNotifications = false;
    }
  }

  private updateAndMaintainNotification(payload: MessagePayload) {
    // // User Payload if required
    // const notificationData = new NotificationDataModel(payload);
    // console.log('Message received notificationData ', notificationData);

    // // Get stored Notifications
    // let recentNotificationsData: NotificationDataModel[] = JSON.parse(
    //   this._storageService.getStorage(StorageNames.RecentNotificationsData)
    // );
    // // Check if data is null assign empty array
    // if (!recentNotificationsData) {
    //   recentNotificationsData = [];
    // }
    // // Adding/Pushing the notification to the top
    // recentNotificationsData.unshift(notificationData);

    // // Remove the 4rth notification as we are maintaing 3 notifications only
    // if (recentNotificationsData.length > 3) {
    //   recentNotificationsData.pop();
    // }
    // // store updated Notifications
    // this._storageService.saveStorage(
    //   StorageNames.RecentNotificationsData,
    //   recentNotificationsData
    // );
    this._recentNotificationsService.addMessage(payload);
    // Once all save update weather to show or hide recent notifications
    this.updateShowRecentNotifications();
  }

  private checkUserIdAndFetchNotifications(): void {
    // Create an interval to check every 500ms
    intervalFromRx(500)
      .pipe(
        takeWhile(() => !this.cookieService.get('user_id')) // Stop once user_id is found
      )
      .subscribe({
        next: () => {
          /* Waiting for user_id */
        },
        complete: () => {
          // Call the API when user_id is available
          this.getNotificationCount();
        },
      });
  }

  private async getNotificationCount(retries = 5, delay = 500): Promise<void> {
    this.userId = this.cookieService.get('user_id');

    if (!this.userId && retries > 0) {
      // Retry after delay if user ID is not set yet
      setTimeout(() => this.getNotificationCount(retries - 1, delay), delay);
      return;
    }

    if (!this.userId) {
      console.error('User ID not found after retries.');
      return; // Optionally handle this case
    }

    // Call your API now that the user ID is available
    this.posts.getNotifications(this.userId).subscribe((data) => {
      console.log(data);
      if (data.FCMHistoryList.length > 0) {
        this._notificationsService.getUnreadCount(data.count);
        const transformedNotifications = data.FCMHistoryList.map(
          (notification) => ({
            collapseKey: '',
            from: '',
            messageId: notification.FCM_Id,
            is_read: notification.is_read,
            notification: {
              title: notification.notification_title,
              body: notification.notification_body,
            },
          })
        );

        transformedNotifications.forEach((notification) => {
          if (!notification.is_read) {
            this._recentNotificationsService.addMessage(notification);
            this.shouldShowRecentNotifications = true;
            this.showBackground = true;
          }
        });
      }
    });
  }
}
