import { Injectable } from '@angular/core';
import { NotificationDataModel } from '../core/models/notification-data.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { MessagePayload } from 'firebase/messaging';
import { StorageNames } from '../constants/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from '../constants/api.endpoint';


@Injectable({
  providedIn: 'root',
})
export class RecentNotificationsService {
  $recentNotificationsData = new BehaviorSubject<NotificationDataModel[]>([
    JSON.parse(
      this._storageService.getStorage(StorageNames.RecentNotificationsData)
    ) || [],
  ]);
  getRecentNotificationsData = this.$recentNotificationsData.asObservable();
  //
  constructor(
    private _storageService: StorageService,
    private httpClient: HttpClient,
  ) {}

  //
  public addMessage(payload: MessagePayload) {
    // Check if notification is generated by logged in user
    // Then don't display notification

    // const pushNotificationGeneratorId =
    //   payload.notification.title?.split('//')[1];
    // const loggedInUserId = this.cookieService.get('user_id');
    // if (
    //   pushNotificationGeneratorId &&
    //   loggedInUserId &&
    //   pushNotificationGeneratorId == loggedInUserId
    // ) {
    //   // Since Notification is generated by the same use
    //   // do nothing
    //   return;
    // }
    // this.pushNotificationService.incementPushNotificationCountBy(1);
    // User Payload if required
    const notificationData = new NotificationDataModel(payload);
    // console.log('Message received notificationData ', notificationData);

    // Get stored Notifications
    let recentNotificationsData: NotificationDataModel[] = JSON.parse(
      this._storageService.getStorage(StorageNames.RecentNotificationsData)
    );
    // Check if data is null assign empty array
    if (!recentNotificationsData) {
      recentNotificationsData = [];
    }
    // Adding/Pushing the notification to the top
    recentNotificationsData.unshift(notificationData);

      // Remove duplicates based on messageID
      recentNotificationsData = recentNotificationsData.filter(
        (notification, index, self) =>
          index === self.findIndex((n) => n.messageId === notification.messageId)
      );

    // Remove the 4rth notification as we are maintaing 3 notifications only
    if (recentNotificationsData.length > 3) {
      recentNotificationsData.pop();
    }
    // store updated Notifications
    this._storageService.saveStorage(
      StorageNames.RecentNotificationsData,
      recentNotificationsData
    );
    // console.log('final data', recentNotificationsData);
    // update the same data in Behaviour Subject
    this.$recentNotificationsData.next(recentNotificationsData);
  }

  public removeNotification(index: number) {
    console.log('index', index);
    // Get stored Notifications
    const recentNotificationsData =
      JSON.parse(
        this._storageService.getStorage(StorageNames.RecentNotificationsData)
      ) ?? [];
    recentNotificationsData.splice(index, 1);
    this._storageService.saveStorage(
      StorageNames.RecentNotificationsData,
      recentNotificationsData
    );
    this.$recentNotificationsData.next(recentNotificationsData);
  }

  UpdatePushNotification(contentId: string, userId): Observable<any> {
    const headersData = new HttpHeaders({
      // Authorization: `Bearer ${userInfo.token}`,
      'Content-Type': 'application/json',
      messageId: contentId,
    });
    const options = {
      headers: headersData,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.UpdateFCMHistory}/${userId}`,
      // payload, //     // Don't send empty payload, it will through error in API call
      {},
      options
    );
  }
}
