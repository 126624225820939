import { Component, OnDestroy, OnInit } from '@angular/core';
import { Adb2cService } from '../services/adb2c.service';
import { AppRoutes } from '../constants/constants';
import { Router } from '@angular/router';
import { PlatformStateService } from '../core/services/platform-state.service';

@Component({
  selector: 'app-authentication-loader',
  templateUrl: './authentication-loader.component.html',
})
export class AuthenticationLoaderComponent implements OnInit, OnDestroy {
  isLoading = true;
  isAuthenticated = false;

  constructor(
    private _adb2cService: Adb2cService, 
    private router: Router,
    private platformState: PlatformStateService
  ) {}

  ngOnInit(): void { 
    // First check with PlatformStateService
    this.platformState.isAuthenticated$.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
      
      if (isAuthenticated) {
        this.navigateToExplore();
      } else {
        // Also check with ADB2C service as a fallback
        if (this._adb2cService.isUserLoggedIn()) {
          // ADB2C reports user is logged in but PlatformState doesn't know yet
          // Attempt to recover the token and user info
          this._adb2cService.attemptTokenRecovery().subscribe(success => {
            if (success) {
              // Token recovery successful, user should now be properly authenticated
              this.navigateToExplore();
            } else {
              // Token recovery failed, show login message
              this.isLoading = false;
            }
          });
        } else {
          // Not logged in in either service, show message
          this.isLoading = false;
        }
      }
    }); 
  }

  navigateToExplore(): void {
    console.log("navigate to explore")
    this.router.navigate([`/${AppRoutes.Explore}`]);
  }

  navigateToHome(): void {
    //make sure there are no lingering cookies or storage
    this._adb2cService.logout();

    console.log("navigate to home")
    this.router.navigate(['/']);
  }

  ngOnDestroy(): void {
    this._adb2cService.destroy();
  }
}
