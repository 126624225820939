import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  IdTokenClaims,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  PromptValue,
  RedirectRequest,
  SsoSilentRequest,
} from '@azure/msal-browser';
import { CookieService } from 'ngx-cookie-service';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfoModel } from '../core/models/user-info.model';
import { StorageService } from './storage.service';
import { AppRoutes, SocialDomains, StorageNames } from '../constants/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../home/auth.service';
import { ApiEndpoints } from '../constants/api.endpoint';
import { PlatformStateService } from '../core/services/platform-state.service';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
  extension_Birthday?: string;
  idp_access_token?: string;
  name?: string;
  family_name?: string;
  extension_PhoneNumber?: string;
};

@Injectable({
  providedIn: 'root',
})
export class Adb2cService implements OnDestroy {
  private msalStatus: string;
  // loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private cookie: CookieService,
    private _storageService: StorageService,
    private httpClient: HttpClient,
    private _authService: AuthService,
    private platformState: PlatformStateService
  ) {}

  public initiateMsalAuthentication() {
    if (
      this.msalStatus === undefined ||
      this.msalStatus === InteractionStatus.None
    ) {
      this.msalAccountAdded();
      this.msalInProgress();
      this.msalSuccess();
      this.msalFailed();
    }
  }

  public tempTrySuccess() {
    if (
      this.msalStatus === undefined ||
      this.msalStatus === InteractionStatus.None
    ) {
      this.msalSuccess();
      this.msalFailed();
    }
  }

  private msalAccountAdded() {
    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          this.router.navigate(['/login']);
        }
      });
  }

  private msalInProgress() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => {
          this.msalStatus = status;
          return status === InteractionStatus.None;
        }),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        // this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  private checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }
  private msalSuccess() {
    // Check If it is Login;
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(async (result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        // console.log('payload', payload);
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
        let isGoogleSignIn = false;
        if (idtoken.idp && idtoken.idp == SocialDomains.Google) {
          isGoogleSignIn = true;
        }
        const userInfoData = new UserInfoModel();
        userInfoData.account_id = idtoken.oid ?? '';
        userInfoData.email = idtoken.emails[0] ?? '';
        userInfoData.first_name = idtoken.name ?? '';
        userInfoData.last_name = idtoken.family_name ?? '';
        userInfoData.phone_no = idtoken.extension_PhoneNumber ?? '';
        userInfoData.token = payload.idToken ?? '';
        userInfoData.birth_date = idtoken.extension_Birthday ?? '';
        userInfoData.isLoggedIn = true;
        const authority = payload.authority;

        // For Social Login+SignIn
        if (isGoogleSignIn) {
          this._storageService.saveStorage(
            StorageNames.isGoogleSignIn,
            isGoogleSignIn
          );
          if (idtoken.idp_access_token) {
            this._storageService.saveStorage(
              StorageNames.idpAccessToken,
              idtoken.idp_access_token
            );
          }

          // console.log('Google SignIn');
          // Creating payload as per existing registration
          const registerationData = {
            user_name: 'fan' + idtoken.oid.substring(0, 8), // send oid first 8 characters as user_name said by Sunny
            user_password: '',
            first_name: userInfoData.first_name,
            last_name: userInfoData.last_name,
            user_mobile: userInfoData.phone_no,
            user_details: {
              user_email: userInfoData.email,
              birth_date: userInfoData.birth_date,
            },
            referrer: '',
            user_type: 'fan',
          }; 

          this.registerUserIntoDb(registerationData).subscribe( (created) => {
            userInfoData.user_id = created
          });

          // Save data into local storage
          this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
          this.getUseIdFromDbAndSaveInStorage(userInfoData, false);
        }
        // For Sign Up and
        else if (
          authority
            .toLowerCase()
            .includes(environment.b2cPolicies.names.signUp.toLowerCase())
        ) {
          // Creating payload as per existing registration
          const registerationData = {
            user_name: 'fan' + idtoken.oid.substring(0, 8), // send oid first 8 characters as user_name said by Sunny
            user_password: '',
            first_name: userInfoData.first_name,
            last_name: userInfoData.last_name,
            user_mobile: userInfoData.phone_no,
            user_details: {
              user_email: userInfoData.email,
              birth_date: userInfoData.birth_date,
            },
            referrer: '',
            user_type: 'fan',
          };

          this.registerUserIntoDb(registerationData).subscribe( (created) => {
          userInfoData.user_id = created
          });

          this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
        } else {
           console.log('Only SignIn',userInfoData);
          // Sign In
          // Save data into local storage
          this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
          this.getUseIdFromDbAndSaveInStorage(userInfoData);
        }
        // this.msalService.instance.setActiveAccount(payload.account);

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          idtoken.acr === environment.b2cPolicies.names.editProfile ||
          idtoken.tfp === environment.b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.msalService.instance
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims?.oid === idtoken.oid &&
                account.idTokenClaims?.sub === idtoken.sub &&
                ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
                  environment.b2cPolicies.names.signIn ||
                  (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
                    environment.b2cPolicies.names.signIn)
            );

          let signInFlowRequest: SsoSilentRequest = {
            authority: environment.b2cPolicies.authorities.signIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signIn policy
          this.msalService.ssoSilent(signInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (
          idtoken.acr === environment.b2cPolicies.names.resetPassword ||
          idtoken.tfp === environment.b2cPolicies.names.resetPassword
        ) {
          let signInFlowRequest: RedirectRequest | PopupRequest = {
            authority: environment.b2cPolicies.authorities.signIn.authority,
            scopes: [...environment.apiConfig.scopes],
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
          };
          this.login(signInFlowRequest);
        }
        return result;
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  signUp() {
    let signUpFlowRequest: RedirectRequest | PopupRequest = {
      authority: environment.b2cPolicies.authorities.signUp.authority,
      scopes: [...environment.apiConfig.scopes],
    };
    this._storageService.saveStorage(StorageNames.IsVerifyCompleted, false);
    this.login(signUpFlowRequest);
  }

  private msalFailed() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority:
              environment.b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };
          this.login(resetPasswordFlowRequest);
        } else if (
          result.error &&
          result.error.message.includes('AADB2C90091')
        ) {
          // Handle cancellation (User canceled the flow)
          this.router.navigate(['/']);
        } else {
          // Other errors (fallback)
          console.error('Login error:', result.error);
          this.router.navigate(['/error']);
        }
      });
  }

  logout() { 
    // Reset platform state
    this.platformState.resetState();

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalService.logoutRedirect();
      const isGoogleSignIn = this._storageService.getStorage(
        StorageNames.isGoogleSignIn
      );
      let newTab = null;

      if (isGoogleSignIn && isGoogleSignIn === 'true') {
        //wrong approach: This was logging users out from their google account across the whole browser not just fanmire
        // In order to Logout from Social Login Google, 
        // we need to open https://accounts.google.com/Logout
        //newTab = window.open('https://accounts.google.com/Logout', '_blank');
        // Attempt to focus on the new tab (this may not work in all browsers)
         
      }
    } 
  }

  editProfile() {
    let editProfileFlowRequest: RedirectRequest | PopupRequest = {
      authority: environment.b2cPolicies.authorities.editProfile.authority,
      scopes: [],
    };

    this.login(editProfileFlowRequest);
  }

  private getADAccountId(userInfo: UserInfoModel): Observable<any> {
    const payload = {
      user_name: userInfo.email.toLocaleLowerCase(),
    };
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');

    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    console.log("LogAD")
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.LogADUser}`,
      payload,
      options
    );
  }
  public getUserByEmail(userInfo: UserInfoModel): Observable<any> {
    const payload = {
      user_email: userInfo.email.toLocaleLowerCase(),
    };
    const headersData = new HttpHeaders({
      Authorization: `Bearer ${userInfo.token}`,
      'Content-Type': 'application/json',
    });
    const options = {
      headers: headersData,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.GetUserByEmail}`,
      payload,
      options
    );
  }
  public saveDeviceToken(deviceToken: string, userId: string): Observable<any> {
    console.log('userId in saveDeviceToken()', userId);
    const headersData = new HttpHeaders({
      'Content-Type': 'application/json',
      deviceId: deviceToken,
    });
    const options = {
      headers: headersData,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.UpdateDevieToken}/${userId}`,
      {},
      options
    );
  }

  registerUserIntoDb(registerationData): Observable<string> { 
    return new Observable<string>(observer => {
      let created_id = '';

      // Call API to register user in MongoDb as well
      this._authService
        .register(registerationData, registerationData.user_type)
        .subscribe((result) => {
          //if registration success
          if (result !== 'exist') {
            //this should return a user id from db to save
            created_id = result; 
            
          } else {
            //registration failed error
            console.log('User is already there!'); 
          } 
        });

      observer.next(created_id);
      observer.complete();
    })
  }

  getUseIdFromDbAndSaveInStorage(userInfoData: UserInfoModel, isRedirectRequired: boolean = true) {
    // Get User_id from API and save in cookie
    this.getADAccountId(userInfoData).subscribe((dbUserId) => {
      // Check If user is not registered in Mongo db
      if (dbUserId == 'No') {
        // Register User into db
        const registerationData = {
          user_name: 'fan' + userInfoData.user_id.substring(0, 8), // send oid first 8 characters as user_name said by Sunny
          user_password: '',
          first_name: userInfoData.first_name,
          last_name: userInfoData.last_name,
          user_mobile: userInfoData.phone_no,
          user_details: {
            user_email: userInfoData.email,
            birth_date: userInfoData.birth_date,
          },
          referrer: '',
          user_type: 'fan',
        };

        this.registerUserIntoDb(registerationData).subscribe( (created) => {
          userInfoData.user_id = created;
          dbUserId = created;
        });
        // Save data into local storage
        this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
        //this.getUseIdFromDbAndSaveInStorage(userInfoData, false);
        this.getADAccountId(userInfoData); 
      }

      this.cookie.set(StorageNames.UserId, dbUserId, 20, '/');
      // get device token
      const currentToken = this._storageService.getStorage(
        StorageNames.DeviceToken
      );
      if (currentToken) {
        // save device token to db
        this.saveDeviceToken(currentToken, dbUserId).subscribe((res) => {
          console.log('token saved in API res', res);
        });
      }
      // Navigate to dashboard once all done
      let googleSignInStatus = this._storageService.getStorage(
        StorageNames.isGoogleSignIn
      );
      if (!googleSignInStatus) {
        
        this.getUserByEmail(userInfoData).subscribe((res) => {
          console.log('user details', res);

          if (res) {
            if (isRedirectRequired && res.user_profile) {
              console.log("send to explore")
              this.router.navigate([`/explore`]);
            } else {  
              console.log("send to interest")
              this.router.navigate([`/interest`]);
            }
          }
          else {            
            //account not found
            console.log("account not found - send to notfound")
            this.router.navigate([`/account-not-found`]);
          }
          
        });
      }
      
      // Update platform state
      this.platformState.setAuthenticationState(true, dbUserId, userInfoData);
    });
  }
  public unSubscribeDeviceToken(
    deviceToken: string,
    userId: string
  ): Observable<any> {
    const headersData = new HttpHeaders({
      'Content-Type': 'application/json',
      deviceId: deviceToken,
    });
    const options = {
      headers: headersData,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.UnsubscribeDeviceToken}/${userId}`,
      {},
      options
    );
  }

  public isUserLoggedIn() {
    const token = this.msalService.instance.getActiveAccount()?.idToken;
    if (token && this.msalService.instance.getAllAccounts().length > 0) {
      return true;
    } else {
      return false;
    }
  }
  public checkLoginAndRedirect() {
    const userInfo: UserInfoModel = JSON.parse(
      this._storageService.getStorage(StorageNames.UserInfo)
    );
    if (this.isUserLoggedIn()) {
      this.router.navigate([`/${AppRoutes.Explore}`]);
    } else if (
      this.msalStatus === InteractionStatus.None ||
      this.msalStatus == undefined
    ) {
      this.router.navigate([`/`]);
    } else {
      this.router.navigate([`/${AppRoutes.Authenticate}`]);
    }
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  // Add a more robust token recovery method
  public attemptTokenRecovery(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      // First check if we actually have accounts
      const accounts = this.msalService.instance.getAllAccounts();
      
      if (accounts.length > 0) {
        // We have accounts, try to set the active account
        this.msalService.instance.setActiveAccount(accounts[0]);
        
        // Try to silently acquire a token to verify it works
        const silentRequest = {
          scopes: [...environment.apiConfig.scopes],
          account: accounts[0],
          forceRefresh: true // Force refresh to ensure we get a fresh token
        };
        
        this.msalService.acquireTokenSilent(silentRequest).subscribe({
          next: (response) => {
            // Token acquisition successful
            // Extract user information from the response
            const idTokenClaims = response.idTokenClaims as IdTokenClaimsWithPolicyId;
            
            // Create user info object
            const userInfoData = new UserInfoModel();
            userInfoData.account_id = idTokenClaims.oid ?? '';
            userInfoData.email = idTokenClaims.emails[0] ?? '';
            userInfoData.first_name = idTokenClaims.name ?? '';
            userInfoData.last_name = idTokenClaims.family_name ?? '';
            userInfoData.phone_no = idTokenClaims.extension_PhoneNumber ?? '';
            userInfoData.token = response.idToken ?? '';
            userInfoData.birth_date = idTokenClaims.extension_Birthday ?? '';
            userInfoData.isLoggedIn = true;
            
            // Save user info
            this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
            
            // Get user ID from DB
            this.getADAccountId(userInfoData).subscribe({
              next: (dbUserId) => {
                if (dbUserId && dbUserId !== 'No') {
                  this.cookie.set(StorageNames.UserId, dbUserId, 20, '/');
                  // Update platform state
                  this.platformState.setAuthenticationState(true, dbUserId, userInfoData);
                  observer.next(true);
                  observer.complete();
                } else {
                  // We couldn't get a user ID
                  observer.next(false);
                  observer.complete();
                }
              },
              error: () => {
                observer.next(false);
                observer.complete();
              }
            });
          },
          error: () => {
            // Token acquisition failed, we need to redirect to login
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        // No accounts available, recovery not possible without full login
        observer.next(false);
        observer.complete();
      }
    });
  }

  // Enhanced method to check token validity
  public checkTokenValidity(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const account = this.msalService.instance.getActiveAccount();
      
      if (!account) {
        observer.next(false);
        observer.complete();
        return;
      }
      
      // Token expiration check (if possible)
      const idTokenClaims = account.idTokenClaims;
      if (idTokenClaims && idTokenClaims['exp']) {
        const expirationTime = idTokenClaims['exp'] * 1000; // Convert to milliseconds
        const currentTime = Date.now();
        
        if (currentTime > expirationTime) {
          // Token has expired
          observer.next(false);
          observer.complete();
          return;
        }
      }
      
      // Also check if user info is available in storage
      const userInfo = JSON.parse(this._storageService.getStorage(StorageNames.UserInfo) || 'null');
      const userId = this.cookie.get('user_id');
      
      if (!userInfo || !userId) {
        observer.next(false);
        observer.complete();
        return;
      }
      
      observer.next(true);
      observer.complete();
    });
  }

  // Explicit method for sign-up flow
  loginWithSignUpFlow(): Observable<void> {
    const request: RedirectRequest = {
      authority: environment.b2cPolicies.authorities.signUp.authority,
      scopes: [...environment.apiConfig.scopes]
    };
    this._storageService.saveStorage(StorageNames.IsVerifyCompleted, false);
    return this.msalService.loginRedirect(request);
  }

  // Explicit method for sign-in flow
  loginWithSignInFlow(): Observable<void> {
    const request: RedirectRequest = {
      authority: environment.b2cPolicies.authorities.signIn.authority,
      scopes: [...environment.apiConfig.scopes]
    };
    return this.msalService.loginRedirect(request);
  }

  // Explicit method for password reset flow
  requestPasswordReset(): Observable<void> {
    const request: RedirectRequest = {
      authority: environment.b2cPolicies.authorities.resetPassword.authority,
      scopes: []
    };
    return this.msalService.loginRedirect(request);
  }

  // Handle token refresh to prevent redirect loops
  refreshTokenSilently(): Observable<AuthenticationResult> {
    const activeAccount = this.msalService.instance.getActiveAccount();
    
    if (!activeAccount) {
      return new Observable(observer => {
        observer.error(new Error('No active account found'));
        observer.complete();
      });
    }
    
    const silentRequest: SsoSilentRequest = {
      scopes: [...environment.apiConfig.scopes],
      account: activeAccount,
      authority: environment.b2cPolicies.authorities.signIn.authority
    };
    
    return this.msalService.ssoSilent(silentRequest);
  }

  // Safer version of login to prevent redirect loops
  loginSafe(userFlowRequest?: RedirectRequest | PopupRequest): Observable<void | AuthenticationResult> {
    // Store the current URL to return to after login
    const currentUrl = this.router.url;
    this._storageService.saveStorage('loginRedirectUrl', currentUrl);

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        return this.msalService.loginPopup({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as PopupRequest);
      } else {
        return this.msalService.loginPopup(userFlowRequest);
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        return this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        return this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }
}
