import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import { StorageNames } from '../constants/constants';
import { UserInfoModel } from '../core/models/user-info.model';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private _storageService: StorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Getting userInfo
    const userInfoData: UserInfoModel = JSON.parse(
      this._storageService.getStorage(StorageNames.UserInfo)
    );
    // console.log('token in interceptor: ', userInfoData.token);
    // Updating header with token
    // if (userInfoData && userInfoData.token) {
    //   req = req.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${userInfoData.token}`,
    //     },
    //   });
    // }

    // console.log('req', req);

    return next.handle(req);
  }
}
