import { Component, OnDestroy, OnInit } from '@angular/core';
import { Adb2cService } from '../services/adb2c.service';
import { AppRoutes } from '../constants/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authentication-loader',
  templateUrl: './authentication-loader.component.html',
})
export class AuthenticationLoaderComponent implements OnInit, OnDestroy {
  constructor(private _adb2cService: Adb2cService, private router: Router) {}
  ngOnInit(): void {
    if (this._adb2cService.isUserLoggedIn()) {
      this.router.navigate([`/${AppRoutes.Explore}`]);
    }
  }
  ngOnDestroy(): void {
    this._adb2cService.destroy();
  }
}
